<template>
  <AppLayout>
    <div class="content-warp">
      <div class="warp-title">
        <div class="warp1-title1">
          <p class="warp-title-text1">11</p>
          <div class="warp-title-text" @click="$router.back(-1)">
            <span> <img src="../../assets/images/fh.png" alt=""  style="margin-bottom: 5px;"/></span>
            &nbsp; 消息中心
          </div>
          <div class="message-b">
            <p class="warp-title-content">{{ imgData.messageTitle }}</p>
            <p class="message-b-1" style="margin-top: -8px;">{{ imgData.createTime }}</p>
          </div>
          <el-divider
	          class="line"
          />
          <div class="warp-img" v-html="imgData.details"></div>
          <div class="warp-2">1</div>
        </div>
      </div>
      <div class="warp-i">1</div>
    </div>
  </AppLayout>
  <HomeBackup />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { MessageId } from "../../api/messageDetail/index";
import AppLayout from "@/components/AppLayout";
import HomeBackup from "@/views/home/components/HomeBackup";
import { useRoute } from "vue-router";

const route = useRoute();
let id = ref(route.params.id);
const imgData = ref({});
const getMessageId = async () => {
  try {
    let data = await MessageId(id.value);
    if (data.code === 200) {
      imgData.value = data.data;
      console.log(imgData.value);
    } else {
      return "暂无数据";
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getMessageId();
});
</script>

<style lang="less">
.content-warp {
  width: 1920px;
  background-color: #f2f2f2;
  .warp-title {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    background: #f2f2f2;
    margin-top: 70px;
    .warp1-title1 {
      width: 1200px;
      margin: 0 auto;
      background: #fff;
      margin-top: 30px;
      .warp-title-text1 {
        background-color: #f2f2f2;
        color: #f2f2f2;
      }

      .warp-title-text {
        margin-top: 55px;
        margin-left: 70px;
        font-size: 18px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
        z-index: 10;
        span {
          display: inline-block;
          width: 19px;
          height: 15px;
          img {
            display: inline-block;
            width: 19px;
            height: 15px;
          }
        }
      }
      .message-b {
        // margin-left: 100px;
        // position: relative;
        // top: -30px;
        // left: 0;
        .warp-title-content {
          // margin-top: 23px;
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: 24px;
          // margin-top: 48px;
          text-align: center;
        }
        .message-b-1 {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          text-align: center;
          margin: auto;
        }
      }
	    .line{
		    background-color: #e1e1e1;
		    width: 1100px;
		    margin-left: 50px
	    }
      .nva-border {
        border: 1px solid #e1e1e1;
        margin-bottom: 60px;
        margin-top: 30px;
        margin-left: 50px;
        margin-right: 50px;
      }
    }
    .warp-img {
      width: 900px;
      background-color: #fff;
      margin: 0 auto;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      img {
        // width: 900px;
        // height: 300px;
        margin-top: 21px;
        margin-bottom: 21px;
        // margin-left: 80px;
      }
      video {
        width: 900px;
        height: 510px;
      }
    }
    .warp-text {
      background: #fff;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .warp-2 {
      height: 40px;
      background-color: #fff;
      color: #fff;
    }
  }
  .warp-i {
    height: 111px;
    background-color: #f2f2f2;
    color: #f2f2f2;
  }
}
</style>
